<template>
  <v-card elevation="3" style="width: 100%; min-height: 500px;">

    <v-row no-gutters class="mx-2">

      <!-- Button -->
      <v-col cols="12">
        <v-row justify="end" no-gutters>
          <v-btn
            disabled
            class="btn-primary"
            color="primary"
            @click="toggleAddDialog(true)">
            {{ $t('projects-impact.tabs.links.buttons.add') }}
          </v-btn>
        </v-row>
      </v-col>

      <!-- Details -->
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="items"
          :server-items-length="totalItems"
          :options.sync="pagination"
          :loading="loading"
          :items-per-page="20"
          sort-by="createdAt"
          :sort-desc="false"
          :footer-props="{'items-per-page-options': itemsPerPage}"
          class="elevation-1">

          <!-- Label -->
          <template v-slot:item.label="{item}">
            {{ item.label }}
          </template>

          <!-- Static Link -->
          <template v-slot:item.static_link="{item}">
            <a :href="item.static_link">{{item.static_link}}</a>
            <ButtonIconCopy :value="item.static_link" />
          </template>

          <!-- Dynamic link -->
          <template v-slot:item.dynamic_link="{item}">
            <a :href="item.dynamic_link">{{item.dynamic_link}}</a>
            <ButtonIconCopy :value="item.dynamic_link" />
          </template>

          <!-- Enabled -->
          <template v-slot:item.enabled="{item}">
            <IconCheckOrTimes :enabled="item.enabled" />
          </template>

          <!-- Actions -->
          <template v-slot:item.actions="{item}">

            <!-- View -->
            <BtnIconDetails @click="toggleDetailsDialog(true, item)" />

            <!-- Edit -->
            <BtnIconEdit @click="toggleEditDialog(true, item)" />

            <!-- Delete -->
            <BtnIconDelete @click="toggleDeleteDialog(true, item)" />
          </template>

        </v-data-table>
      </v-col>
    </v-row>

    <!-- Main qr code dialog -->
    <LinkMainDialog
      v-if="showDialog.addOrEdit"
      :value="showDialog.addOrEdit"
      :item="currentItem"
      :product="product"
      :study="study"
      @close="(needRefresh) => toggleEditDialog(false, null, needRefresh)"
    />

    <!-- Qr code details dialog -->
    <LinkDetailsDialog
      v-if="showDialog.details"
      :value="showDialog.details"
      :link="currentItem"
      @close="(needRefresh) => toggleDetailsDialog(false, null, needRefresh)"
    />

    <!-- Delete dialog -->
    <DeleteDialog
      v-if="showDialog.delete"
      :value="showDialog.delete"
      :title="$t('links.deleteDialog.title')"
      :isLoading="isLoadingBtnSave"
      @submit="deleteQrCode"
      @close="toggleDeleteDialog(false)"
    />

  </v-card>
</template>

<script>
import vuetifyDataTable from "@/mixins/vuetifyDataTable";
import formatter           from "@/mixins/formatter";

export default {
  name: "ProjectImpactLinksTab",

  components: {
    DeleteDialog: () => import("@/components/Common/DeleteDialog"),
    ButtonIconCopy: () => import("@/components/Common/Buttons/ButtonIconCopy"),
    LinkDetailsDialog: () => import("@/components/Links/LinkDetailsDialog"),
    BtnIconDelete: () => import("@/components/Common/Buttons/BtnIconDelete"),
    BtnIconEdit: () => import("@/components/Common/Buttons/BtnIconEdit"),
    BtnIconDetails: () => import("@/components/Common/Buttons/BtnIconDetails"),
    LinkMainDialog: () => import("@/components/Links/LinkMainDialog"),
    IconCheckOrTimes: () => import("@/components/Common/IconCheckOrTimes"),
  },

  mixins: [vuetifyDataTable, formatter],

  props: {
    project_impact: {type: Object, default: () => null},
  },

  data() {
    return {
      headers: [
        {
          text: this.$t('links.datatable.headers.label'),
          value: "label",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('links.datatable.headers.static_link'),
          value: "static_link",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('links.datatable.headers.dynamic_link'),
          value: "dynamic_link",
          sortable: false,
          width: "10%"
        },
        {
          text: this.$t('links.datatable.headers.enabled'),
          value: "enabled",
          sortable: false,
          width: "5%"
        },
        {
          text: "",
          value: "actions",
          sortable: false,
          width: "20%"
        },
      ],

      URL_API: {
        GET_ALL: `/links`
      },

      filters: {
        projects_impact: [this.project_impact.id]
      },

      showDialog: {
        addOrEdit: false,
        details: false,
        delete: false
      },

      isLoadingBtnSave: false,
      currentItem: null,
      currentItemLoadingBtn: false
    }
  },

  methods: {
    toggleAddDialog(val, reloadData = false) {
      if (reloadData)
        this.searchTrigger();
      this.showDialog.addOrEdit = val;
    },

    toggleEditDialog(val, qrCode = null, reloadData = false) {
      this.currentItem = qrCode;
      if (reloadData)
        this.searchTrigger();
      this.showDialog.addOrEdit = val;
    },

    toggleDeleteDialog(val, qrCode = null, reloadData = false) {
      this.currentItem = qrCode;
      if (reloadData)
        this.searchTrigger();
      this.showDialog.delete = val;
    },

    toggleDetailsDialog(val, qrCode = null, reloadData = false) {
      this.currentItem = qrCode;
      if (reloadData)
        this.searchTrigger();
      this.showDialog.details = val;
    },

    deleteQrCode() {
      this.isLoadingBtnSave = true;
      this.$http
        .delete(`/links/${this.currentItem.id}`, {
          headers: {Authorization: "Bearer " + this.$session.get('jwt')}
        })
        .then(res => {
          this.$store.commit("toast/showSuccess", this.$t('links.deleteDialog.success'));
          this.toggleDeleteDialog(false, null, true);
        })
        .catch(err => {
          this.$store.commit("alert/showErrorAxios", err);
        })
        .finally(() => {
          this.isLoadingBtnSave = false;
        });
    },
  }
}
</script>

<style scoped>

</style>
